<template>
  <div
    class="modal fade"
    id="modalAddProduct"
    tabindex="-1"
    aria-labelledby="modalAddProductLabel"
    aria-hidden="true"
  >
    <LoadingSpinner :show="loading" />
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalAddProductLabel">
            Adicionar produto no estoque da máquina
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="cleanFields"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Picture :imageSrc="productImage" />
          <p class="title-4">
            Quantidade no estoque: {{ productAmountAvailability }}
          </p>
          <ValidationObserver ref="modalAddProduct" v-slot="{ handleSubmit }">
            <form
              class="row g-3 justify-content-center"
              @submit.prevent="handleSubmit(onSubmitAddProductInventory)"
            >
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="Produto"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label
                        for="inputSelectedProduct"
                        class="form-label text-dark"
                        >Produto</label
                      >
                      <multiselect
                        :max-height="150"
                        :maxSelectedLabels="1"
                        id="inputSelectedProduct"
                        :showLabels="false"
                        v-model="selectedProduct"
                        :options="multiSelectStockProducts"
                        :custom-label="titleWithExpiration"
                        placeholder="Digite aqui o nome do produto que deseja adicionar"
                        label="title"
                        track-by="title"
                      >
                        <template #option="slotProps">
                          <div class="flex align-items-center text-dark">
                            <img
                              :alt="slotProps.option.title"
                              :src="slotProps.option.image"
                              class="mr-2"
                              style="width: 18px"
                            />
                            <span>{{ slotProps.option.title }} | </span>
                            <span
                              >Validade
                              <strong
                                style="
                                  display: inline-block;
                                  padding: 4px;
                                  border-radius: 4px;
                                "
                                :class="
                                  isValidDate(slotProps.option.expiration)
                                "
                                >({{
                                  dateFormatted(slotProps.option.expiration)
                                }})</strong
                              >
                            </span>
                            <span>
                              Quant. Estq
                              {{ slotProps.option.amount }}</span
                            >
                          </div>
                        </template>
                      </multiselect>
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="Disponibilidade"
                      :rules="
                        'required|numeric|min_value:1|max_value:' +
                        productAmountAvailability
                      "
                      v-slot="{ errors }"
                    >
                      <label
                        for="inputAvailability"
                        class="form-label text-dark"
                        >Quantidade
                        <span class="badge badge-default"
                          >{{ amount ? amount : 0 }}
                          /
                          {{ productAmountAvailability }}</span
                        ></label
                      >
                      <input
                        v-model="amount"
                        type="number"
                        min="0"
                        class="form-control text-dark"
                        id="inputAvailability"
                      />
                      <p class="fw-normal text-danger p-relative">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="Unidade Comercial"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label
                        for="inputProductMoviment"
                        class="form-label text-dark"
                        >Tipo Movimentação</label
                      >
                      <select
                        v-model="action"
                        class="form-control text-dark"
                        id="inputProductMoviment"
                      >
                        <option
                          v-for="action in addActions"
                          :value="action.value"
                          :key="action.value"
                        >
                          {{ action.label }}
                        </option>
                      </select>
                      <span class="fw-normal text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="row row py-4">
                  <div class="col-12 text-right">
                    <button
                      type="button"
                      class="btn btn-secondary mr-3"
                      data-dismiss="modal"
                      @click="cleanFields"
                    >
                      Fechar
                    </button>
                    <button type="submit" class="btn btn-primary btn-">
                      Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import {
  maxValue,
  minValue,
  numericField,
  validationRequire,
} from "../../../middleware/validations";
import Picture from "../../Picture.vue";
import LoadingSpinner from "../../Spinner.vue";
moment.locale("pt-BR");
maxValue();
minValue();
numericField();
validationRequire();
export default {
  name: "ModalAddProduct",
  components: {
    ValidationObserver,
    ValidationProvider,
    Picture,
    LoadingSpinner,
    Multiselect,
  },
  props: {
    productImage: String,
    machineId: String,
    formAddProduct: {
      product_id: String,
    },
    stockProducts: Array,
  },
  data() {
    return {
      loading: false,
      selectedProduct: null,
      productAmountAvailability: 0,
      addActions: [
        { label: "ENTRADA", value: 1 },
        { label: "ENTRADA DEVOLUCAO VENDA", value: 2 },
        { label: "ENTRADA OUTRAS", value: 3 },
      ],
      products: [],
      action: 1,
      amount: 0,
      expiration: "",
      warehouse_id: null,
      company_id: null,
    };
  },
  mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }

    this.warehouse_id = warehouse_id;
    this.company_id = company_id;
    const intialLoading = async () => {
      await this.getProducts();
    };
    intialLoading();
  },
  methods: {
    isValidDate(date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const now = moment(new Date()).format("YYYY-MM-DD");
      if (moment(formatedDate).isSame(now)) return "badge-primary";
      if (moment(now).isAfter(formatedDate)) return "badge-danger";
      return "badge-success";
    },
    dateFormatted(date) {
      return moment(date).format("L");
    },
    titleWithExpiration({ title, acquisition_price }) {
      if (!title) return "";
      return `${title} | Preço Aquisição ${this.toBRL(
        acquisition_price / 100
      )}`;
    },
    toBRL(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    cleanFields() {
      this.selectedProduct = null;
      this.productAmountAvailability = 0;
      this.amount = 0;
      this.action = 1;
      this.expiration = "";
      this.$refs.modalAddProduct.reset();
    },
    async getProducts() {
      const params = {
        company_id: this.company_id,
        warehouse_id: this.warehouse_id,
      };
      const { data } = await this.$http.get("/products/stock", {
        params,
      });
      const { products } = await data;
      this.products = products;
    },
    async onSubmitAddProductInventory() {
      try {
        this.loading = true;
        const params = {
          machine_id: this.machineId,
          product_id: this.formAddProduct.product_id,
          expiration: this.expiration,
          amount: Number(this.amount),
          action: this.action,
          warehouse_id: this.warehouse_id,
          company_id: this.company_id,
        };
        await this.$http.post(`/product/machine/add`, params);
        this.$toast.success("Produto atualizado com sucesso");
        this.$emit("refresh-page");
        $(`#modalAddProduct`).modal("hide");
        this.cleanFields();
        this.loading = false;
      } catch (err) {
        $(`#modalAddProduct`).modal("hide");
        this.cleanFields();
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
  },
  computed: {
    multiSelectStockProducts() {
      let newProducts = [];
      this.stockProducts.forEach((product) => {
        for (const productStock in product.stock) {
          newProducts.push({
            ...product.stock[productStock],
            title: product.title,
            description: product.description,
            image: product.image,
            discount: product.discount,
          });
        }
      });
      return newProducts.filter(
        (stockItem) => stockItem.product_id == this.formAddProduct.product_id
      );
    },
  },
  watch: {
    selectedProduct(productSelected) {
      if (productSelected) {
        this.productAmountAvailability = productSelected.amount;
        this.formAddProduct.product_id = productSelected.product_id;
        this.expiration = productSelected.expiration;
      } else {
        this.cleanFields();
      }
    },
  },
};
</script>
<style lang="css" scoped>
.multiselect__element,
.multiselect__input,
.multiselect__placeholder,
.multiselect__single {
  font-size: 0.75rem;
  color: #212529;
}
.form-control[disabled] {
  background-color: #fff;
  border-color: #e8e8e8;
  color: #838383 !important;
}
</style>