<template>
  <card class="card" title="Fazer inventário do estoque">
    <!-- Modal Add Product -->
    <div
      class="modal fade"
      id="modalAddProduct"
      tabindex="-1"
      aria-labelledby="modalAddProductLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAddProductLabel">
              Adicionar produto no estoque
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="picture-container mx-auto">
              <ProductImage :size="75" :src="selectedProduct.image" />
            </div>
            <ValidationObserver ref="modalAddProduct" v-slot="{ handleSubmit }">
              <form
                class="row g-3 justify-content-center"
                @submit.prevent="
                  handleSubmit(() => onSubmitAddProduct('modalAddProduct'))
                "
              >
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Produto"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputProductSelect"
                          class="form-label text-dark"
                          >Produto</label
                        >
                        <multiselect
                          :max-height="150"
                          :maxSelectedLabels="1"
                          id="inputProductSelect"
                          :showLabels="false"
                          v-model="selectedProduct"
                          :options="emptyStockProducts"
                          placeholder="Nome do produto"
                          label="title"
                          track-by="title"
                        >
                          <template #option="slotProps">
                            <div class="flex align-items-center text-dark">
                              <img
                                :alt="slotProps.option.title"
                                :src="slotProps.option.image"
                                class="mr-2"
                                style="width: 18px"
                              />
                              <span>{{ slotProps.option.title }} | </span>
                              <span
                                >Validade
                                <strong
                                  style="
                                    display: inline-block;
                                    padding: 4px;
                                    border-radius: 4px;
                                  "
                                  :class="
                                    isValidDate(slotProps.option.expiration)
                                  "
                                  >({{
                                    dateFormatted(slotProps.option.expiration)
                                  }})</strong
                                >
                              </span>
                            </div>
                          </template>
                        </multiselect>
                        <p class="fw-normal text-danger p-relative">
                          {{ errors[0] }}
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <ValidationProvider
                        name="Disponibilidade"
                        rules="required|min_value:1"
                        v-slot="{ errors }"
                      >
                        <label
                          for="inputAvailability"
                          class="form-label text-dark"
                          >Quantidade que deseja adicionar</label
                        >
                        <input
                          type="number"
                          min="0"
                          v-model="selectedProduct.quantity"
                          class="form-control text-dark"
                          id="inputAvailability"
                        />
                        <p class="fw-normal text-danger p-relative">
                          {{ errors[0] }}
                        </p>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="row row py-4">
                    <div class="col-12 text-right">
                      <button
                        type="button"
                        class="btn btn-secondary mr-3"
                        data-dismiss="modal"
                      >
                        Fechar
                      </button>
                      <button
                        type="submit"
                        :disabled="loading"
                        class="btn btn-primary"
                      >
                        Adicionar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- End Add Product -->

    <LoadingSpinner :show="loading" />
    <div class="card-body">
      <div class="row justify-content-between mb-5">
        <div>
          <button
            data-toggle="modal"
            data-target="#modalAddProduct"
            :disabled="!editMode"
            class="btn btn-secondary"
          >
            Adicionar produto
          </button>
        </div>
        <a ref="csvDownloadLink" v-show="false" class="btn btn-info"
          >Download Excel</a
        >
        <button
          @click="onSubmitInventory"
          class="btn btn-success"
          :disabled="!editMode"
        >
          Salvar e gerar relatório
        </button>
      </div>
      <StockInventoryPDF
        name="INVENTÁRIO_ESTOQUE"
        :hasExpirationDate="true"
        ref="stockInventoryPDF"
        :stockInventory="stockProducts"
        :user="{ name: manager.name, email: manager.email }"
      />
      <table class="table table-responsive-xl text-center">
        <thead>
          <tr>
            <th scope="col">Imagem do Produto</th>
            <th scope="col">Produto</th>
            <th scope="col">Quant Estoque</th>
            <th scope="col">Data Validade</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) of stockProducts" :key="index">
            <td>
              <ProductImage :size="55" :src="product.image" />
            </td>
            <td>
              <div class="container-quantity">
                <button
                  :disabled="!editMode"
                  class="quantity-items btn btn-danger px-3 py-2"
                  @click="handleQuantityProduct(product, 'remove')"
                >
                  -
                </button>
                <input
                  class="mx-2 font-weight-bold input-quantity"
                  type="number"
                  step="1"
                  min="0"
                  v-model="product.quantity"
                />
                <button
                  :disabled="!editMode"
                  class="quantity-items btn btn-success px-3 py-2"
                  @click="handleQuantityProduct(product, 'add')"
                >
                  +
                </button>
              </div>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ product.title }}
              </p>
            </td>
            <td>
              <p
                style="font-size: 0.8rem"
                class="badge text-dark mx-2"
                :class="isValidDate(product.expiration)"
              >
                {{ dateFormatted(product.expiration) }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </card>
</template>
<script>
import CSVReport from "../../services/CSVReport";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import ProductImage from "../../components/ProductImage.vue";
import LoadingSpinner from "../../components/Spinner.vue";
import StockInventoryPDF from "../../components/StockInventoryPDF/StockInventoryPDF.vue";
import {
  minValue,
  positive,
  validationRequire,
} from "../../middleware/validations";

StockInventoryPDF;
moment.locale("pt-BR");
validationRequire();
minValue();
positive();

export default {
  name: "CreateStockInventory",
  components: {
    LoadingSpinner,
    ProductImage,
    ValidationObserver,
    ValidationProvider,
    Multiselect,
    StockInventoryPDF,
  },
  data() {
    return {
      loading: false,
      isRefreshing: false,
      warehouse_id: "",
      company_id: "",
      selectedProduct: {},
      stockProducts: [],
      emptyStockProducts: [],
      editMode: true,
      manager: {},
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.manager = this.$store.getters["manager/getManager"];
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    const initialLoading = async () => {
      await this.getProductsStock();
    };
    initialLoading();
  },
  methods: {
    daysToExpiration(date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const now = moment(new Date()).format("YYYY-MM-DD");
      const daysToExpiration = moment(formatedDate).diff(moment(now), "days");
      let text = "";
      if (daysToExpiration == 0) {
        text = "Vence Hoje";
      } else if (daysToExpiration < 0) {
        text = "Produto Vencido";
      } else if (daysToExpiration == 1) {
        text = "Vence amanhã";
      } else {
        text = `${daysToExpiration} Dias para vencer`;
      }
      return {
        text,
        days: daysToExpiration,
      };
    },
    async onSubmitAddProduct(modalId) {
      const { product_id, expiration, quantity, image, title } =
        this.selectedProduct;
      const addProduct = {
        product_id,
        image,
        expiration,
        quantity: Number(quantity),
        title,
      };
      this.stockProducts.push(addProduct);
      this.$refs.modalAddProduct.reset();
      $(`#${modalId}`).modal("hide");
      this.$toast.success(`${this.selectedProduct.title} adicionado`);
      this.cleanAddProductModal();
    },
    async onSubmitInventory() {
      const items = this.stockProducts.map((item) => {
        const { product_id, expiration, quantity } = item;
        const { text, days } = this.daysToExpiration(expiration);
        return {
          product_id,
          expiration,
          quantity: Number(quantity),
          daysToExpiration: days,
          daysToExpirationText: text,
        };
      });
      this.stockProducts = items;
      const response = await this.createStockInventory(items);
      const { inventory } = response.data;
      this.CSVReportInventory();
      this.PDFReportInventory();
      this.$router.push({
        name: "stockInventory",
        params: { id: inventory.inventory_id },
      });
    },
    async createStockInventory(items) {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          profile_id: this.manager.manager_id,
          products: items,
        };
        const response = await this.$http.post("stock/inventory", params);
        this.$toast.success("Inventário feito com sucesso");
        this.loading = false;
        this.editMode = false;
        return response;
      } catch (err) {
        this.isRefreshing = true;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getProductsStock() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
        };
        const { data } = await this.$http.get("/products/stock", {
          params,
        });
        const stockProducts = data.products;
        this.getProductsStockList(stockProducts);
        this.isRefreshing = false;
        this.loading = false;
      } catch (err) {
        this.isRefreshing = true;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    cleanAddProductModal() {
      this.selectedProduct = {};
    },
    CSVReportInventory() {
      const csvReport = new CSVReport(this.stockProducts, "INVENTÁRIO_ESTOQUE");
      this.$refs.csvDownloadLink.href = csvReport.execute();
      this.$refs.csvDownloadLink.download = csvReport.name;
      this.$refs.csvDownloadLink.click();
    },
    PDFReportInventory() {
      this.$refs.stockInventoryPDF.$refs.html2Pdf.generatePdf();
    },
    handleQuantityProduct(item, operation) {
      this.stockProducts = this.stockProducts.map((product) => {
        if (
          product.product_id == item.product_id &&
          product.expiration == item.expiration
        ) {
          if (operation == "add") {
            product.quantity = Number(product.quantity) + 1;
          } else {
            if (!product.quantity == 0)
              product.quantity = Number(product.quantity) - 1;
          }
        }
        return product;
      });
    },
    isValidDate(date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const now = moment(new Date()).format("YYYY-MM-DD");
      if (moment(formatedDate).isSame(now)) return "badge-primary";
      if (moment(now).isAfter(formatedDate)) return "badge-danger";
      return "badge-success";
    },
    dateFormatted(date) {
      return moment(date).format("L");
    },
    getProductsStockList(products) {
      let newStockProductsList = [];
      let emptyStockProductsList = [];
      products.forEach((product) => {
        product.stock.forEach((stockProduct) => {
          if (stockProduct.amount > 0) {
            newStockProductsList.push({
              product_id: product.product_id,
              title: product.title,
              image: product.image,
              quantity: 0,
              expiration: stockProduct.expiration,
            });
          } else {
            emptyStockProductsList.push({
              product_id: product.product_id,
              title: product.title,
              image: product.image,
              quantity: 0,
              expiration: stockProduct.expiration,
            });
          }
        });
      });
      this.stockProducts = newStockProductsList;
      this.emptyStockProducts = emptyStockProductsList;
    },
  },
  watch: {
    selectedProduct() {
      if (this.selectedProduct == null) {
        this.selectedProduct = {};
      }
    },
  },
};
</script>

<style lang="css" scoped>
.container-quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.container-quantity .input-quantity {
  font-size: 1.2rem;
  background: none;
  text-align: center;
  color: white;
  border: none;
  width: 80px;
  font-weight: bold;
  border: 1px solid white;
  border-radius: 12px;
  appearance: textfield;
  padding: 8px 0;
  -moz-appearance: textfield;
}

.input-quantity:focus {
  outline: none;
}

.container-quantity .quantity-items {
  font-size: 1.2rem;
  cursor: pointer;
}
</style>
