<template>
  <card class="card" title="Inventário do estoque">
    <LoadingSpinner :show="loading" />
    <div class="card-body">
      <div class="row mb-5 justify-content-between align-items-end">
        <div>
          <p>Relatório Estoque</p>
          <a
            class="badge-donwload -pdf mr-2"
            @click="handleGenerateReports({ type: 'pdf', diff: false })"
            >PDF</a
          >
          <a
            class="badge-donwload -excel"
            ref="RefCSVReportLink"
            @click="handleGenerateReports({ type: 'csv', diff: false })"
            >Excel</a
          >
        </div>
        <div v-show="isAdmin()">
          <p>Relatório Diferença Estoque</p>
          <a
            class="badge-donwload -pdf mr-2"
            @click="handleGenerateReports({ type: 'pdf', diff: true })"
            >PDF</a
          >
          <a
            class="badge-donwload -excel"
            ref="RefCSVReportDiffLink"
            @click="handleGenerateReports({ type: 'csv', diff: true })"
            >Excel</a
          >
        </div>
        <div>
          <button
            class="btn btn-success"
            :disabled="
              INVENTORY_STATUS[stockInventory.status] ==
                INVENTORY_STATUS.PROCESSED && isAdmin()
            "
            @click="handleProcessInventory"
          >
            Processar inventário
          </button>
        </div>
      </div>
      <div class="row justify-content-between px-3">
        <router-link
          class="font-weight-bold text-primary"
          :class="{
            disabled:
              INVENTORY_STATUS[stockInventory.status] ==
              INVENTORY_STATUS.PROCESSED,
          }"
          :to="`/edit/stock/inventory/${stockInventory.inventory_id}`"
          >Editar
        </router-link>
        <p class="text-left">
          {{ stockInventory.user.name }} | {{ stockInventory.user.email }} |
          {{ dateFormatted(stockInventory.date) }}
        </p>
      </div>

      <StockInventoryPDF
        name="INVENTÁRIO_ESTOQUE"
        :hasExpirationDate="true"
        ref="stockInventoryPDF"
        :stockInventory="stockInventory.products"
        :user="{
          name: stockInventory.user.name,
          email: stockInventory.user.email,
        }"
        :date="stockInventory.date"
      />
      <StockInventoryDiffPDF
        name="RELATÓRIO_DIFERENÇA_ESTOQUE"
        ref="stockInventoryDiffPDF"
        :items="stockInventoryDiff"
        :user="{
          name: stockInventory.user.name,
          email: stockInventory.user.email,
        }"
        :date="stockInventory.date"
      />
      <table class="table table-responsive-xl text-center">
        <thead>
          <tr>
            <th scope="col">Imagem do Produto</th>
            <th scope="col">Produto</th>
            <th scope="col">Quantidade</th>
            <th scope="col">Data Validade</th>
            <th scope="col">Vencimento</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) of stockInventory.products" :key="index">
            <td>
              <ProductImage :size="55" :src="product.image" />
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ product.title }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ product.quantity }}
              </p>
            </td>
            <td>
              <ExpirationBadge :date="product.expiration" />
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ product.daysToExpirationText }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </card>
</template>
<script>
import CSVReport from "../../services/CSVReport";
import CSVReportDiff from "../../services/CSVReportDiff";
import { InventoryStatus } from "@/services/InventoryStatus";
import moment from "moment";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapGetters } from "vuex/dist/vuex.common.js";
import ExpirationBadge from "../../components/ExpirationBadge.vue";
import ProductImage from "../../components/ProductImage.vue";
import LoadingSpinner from "../../components/Spinner.vue";
import StockInventoryDiffPDF from "../../components/StockInventoryDiffPDF/StockInventoryDiffPDF.vue";
import StockInventoryPDF from "../../components/StockInventoryPDF/StockInventoryPDF.vue";
moment.locale("pt-BR");

export default {
  name: "StockInventory",
  components: {
    LoadingSpinner,
    ProductImage,
    ExpirationBadge,
    StockInventoryPDF,
    StockInventoryDiffPDF,
  },
  data() {
    return {
      loading: true,
      isRefreshing: false,
      warehouse_id: "",
      company_id: "",
      INVENTORY_STATUS: InventoryStatus,
      stockInventory: {
        user: { id: "", name: "", email: "" },
        date: "",
        products: [],
      },
      inventoryId: null,
      stockInventoryDiff: [],
    };
  },
  mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.inventoryId = this.$router.history.current.params.id;
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    const initialLoading = async () => {
      await this.getStockInventory();
      await this.getInventoryDiff();
    };
    initialLoading();
  },
  methods: {
    ...mapGetters({ isAdmin: "manager/isManagerAdmin" }),
    daysExpiration(date) {
      const formatedDate = moment(date).format("YYYY-MM-DD");
      const now = moment(new Date()).format("YYYY-MM-DD");
      return moment(formatedDate).diff(moment(now), "days");
    },
    daysToExpiration(date) {
      const daysToExpiration = this.daysExpiration(date);
      let text = "";
      if (daysToExpiration == 0) {
        text = "Vence Hoje";
      } else if (daysToExpiration < 0) {
        text = "Produto Vencido";
      } else if (daysToExpiration == 1) {
        text = "Vence amanhã";
      } else {
        text = `${daysToExpiration} Dias para vencer`;
      }
      return {
        text,
        days: daysToExpiration,
      };
    },
    async processInventory() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          inventory_id: this.inventoryId,
        };
        await this.$http.post("stock/inventory/process", params);
        this.$toast.success("Inventário processado com sucesso!");
        await this.getStockInventory();
        this.loading = false;
      } catch (err) {
        this.isRefreshing = true;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getInventoryDiff() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          inventory_id: this.inventoryId,
        };
        const response = await this.$http.get("stock/inventory/diff", {
          params,
        });
        this.stockInventoryDiff = response.data.inventory.map((product) => {
          return {
            product_id: product.product.product_id,
            title: product.product.product_title,
            expiration: product.product.expiration,
            image: product.product.product_image,
            system_quantity: product.system_quantity,
            physical_quantity: product.physical_quantity,
            quantity_difference: product.quantity_difference,
            unit_value: product.unit_value,
            total_value: product.total_value,
          };
        });
        this.loading = false;
      } catch (err) {
        this.isRefreshing = true;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async refreshStockReports() {
      this.isRefreshing = true;
      await this.getStockReports();
    },
    async getStockInventory() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          inventory_id: this.inventoryId,
        };
        const { data } = await this.$http.get("/stock/inventory", {
          params,
        });
        this.stockInventory = data.inventory;
        this.stockInventory.products = this.stockInventory.products.map(
          (product) => {
            const { text, days } = this.daysToExpiration(product.expiration);
            return {
              ...product,
              daysToExpiration: days,
              daysToExpirationText: text,
            };
          }
        );
        this.isRefreshing = false;
        this.loading = false;
      } catch (err) {
        this.isRefreshing = true;
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleGenerateReports(report) {
      if (report.type == "pdf") {
        if (report.diff) {
          this.PDFDiffReportInventory();
        } else {
          this.PDFReportInventory();
        }
      } else {
        if (report.diff) {
          this.CSVDiffReportInventory();
        } else {
          this.CSVReportInventory();
        }
      }
    },
    dateFormatted(date) {
      return moment(date).format("L LT");
    },
    PDFDiffReportInventory() {
      this.$refs.stockInventoryDiffPDF.$refs.html2Pdf.generatePdf();
    },
    PDFReportInventory() {
      this.$refs.stockInventoryPDF.$refs.html2Pdf.generatePdf();
    },
    CSVReportInventory() {
      const csvReport = new CSVReport(
        this.stockInventory.products,
        "INVENTÁRIO_ESTOQUE"
      );
      this.$refs.RefCSVReportLink.href = csvReport.execute();
      this.$refs.RefCSVReportLink.download = csvReport.name;
    },
    CSVDiffReportInventory() {
      const csvReportDiff = new CSVReportDiff(
        this.stockInventoryDiff,
        "RELATÓRIO_DIFERENÇA_ESTOQUE"
      );
      this.$refs.RefCSVReportDiffLink.href = csvReportDiff.execute();
      this.$refs.RefCSVReportDiffLink.download = csvReportDiff.name;
    },
    handleProcessInventory() {
      this.processInventory();
    },
  },
};
</script>

<style lang="css" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.badge-donwload {
  padding: 0.4rem 0.6rem;
  color: #fff !important;
  font-weight: 600;
  cursor: pointer;
  border-radius: 6px;
}

.badge-donwload:visited {
  color: #fff;
}

.badge-donwload.-pdf {
  background-color: #f95738;
}

.badge-donwload.-excel {
  background-color: #57cc99;
}
</style>
