<template>
  <card class="p-4" title="Relatório Estoque Mínimo">
    <p class="display-4 mb-4">{{ selectedMachine.description }}</p>
    <div class="row align-items-end">
      <div class="col-12 col-md-6">
        <label for="selectMachine" class="form-label text-light h4"
          >Escolha uma máquina</label
        >
        <select
          v-model="selectedMachine"
          class="form-control"
          id="selectMachine"
        >
          <option class="text-light" :selected="!selectedMachine" :value="null">
            --- Selecione uma máquina ---
          </option>
          <option
            class="bg-light text-dark"
            :value="machine"
            :selected="selectedMachine"
            v-for="machine in machinesList"
            :key="machine.machine_id"
          >
            {{ machine.description }}
          </option>
        </select>
      </div>
      <div class="col-12 col-md-6 my-2 my-md-0">
        <div class="text-left text-md-right">
          <a class="mr-2" @click="handleGenerateReports({ type: 'pdf' })"
            ><base-button type="danger">PDF</base-button></a
          >
          <a
            ref="RefCSVReportLink"
            @click="handleGenerateReports({ type: 'csv' })"
            ><base-button type="success">Excel</base-button></a
          >
        </div>
      </div>
    </div>
    <PDF
      :pdfHeaders="pdfHeader"
      name="Relatório Estoque Mínimo"
      ref="pdfReport"
      :data="csvMetricData"
    />
    <LoadingSpinner :show="loading" />
    <table class="table table table-responsive-xl text-center">
      <thead>
        <tr>
          <th scope="col">Imagem</th>
          <th scope="col">Produto</th>
          <th scope="col">Quant. estoque</th>
          <th scope="col">Média vendas</th>
          <th scope="col">Estoque mínimo</th>
          <th scope="col">Quant. reposição</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product of products" :key="product.product_id">
          <td>
            <ProductImage :size="54" :src="product.image" />
          </td>
          <td>
            <p class="font-weight-bold">{{ product.title }}</p>
          </td>
          <td>
            <p class="font-weight-bold">{{ product.stock_quantity }}</p>
          </td>
          <td>
            <p class="font-weight-bold">{{ product.sold_quantity }}</p>
          </td>
          <td>
            <p class="font-weight-bold">{{ product.minimum_stock }}</p>
          </td>
          <td>
            <p class="font-weight-bold">
              {{ product.replacement_quantity }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </card>
</template>
<script>
import CSV from "../services/CSV";
import LoadingSpinner from "../components/Spinner.vue";
import ProductImage from "../components/ProductImage.vue";
import PDF from "../components/PDF/PDF.vue";
import BaseButton from "../components/BaseButton.vue";

export default {
  name: "MinimumStockReport",
  components: {
    LoadingSpinner,
    ProductImage,
    PDF,
    BaseButton,
  },
  data() {
    return {
      machinesList: [],
      loading: false,
      company_id: "",
      warehouse_id: "",
      products: [],
      selectedMachine: {},
      csvMetricData: [],
      pdfHeader: [
        "Produto",
        "Quant. Estoque",
        "Media. Vendas",
        "Est. Mínimo",
        "Quant. Reposição",
      ],
    };
  },
  async mounted() {
    const managerCompany = JSON.parse(localStorage.getItem("managerCompany"));
    if (managerCompany) {
      this.$store.commit("company/storeCompany", managerCompany);
    }
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    await this.getMachines();
  },
  methods: {
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id:
              this.$store.getters["company/getManagerCompany"].company_id,
          },
        });
        this.machinesList = data.machines;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getStockProductMetrics() {
      try {
        if (!this.selectedMachine.machine_id) {
          this.$toast.warning("Selecione uma máquina");
          return;
        }
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          machine_id: this.selectedMachine.machine_id,
        };
        this.loading = true;
        const { data } = await this.$http.get("/stock/metric", {
          params,
        });
        this.products = data.metrics;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async handleGenerateReports(report) {
      if (report.type == "pdf") {
        this.pdfReport();
      } else {
        this.csvReport();
      }
    },
    pdfReport() {
      this.csvMetricData = this.products.map((product) => {
        return {
          title: product.title,
          stock_quantity: product.stock_quantity,
          sold_quantity: product.sold_quantity,
          minimum_stock: product.minimum_stock,
          replacement_quantity: product.replacement_quantity,
        };
      });
      this.$refs.pdfReport.$refs.html2Pdf.generatePdf();
    },
    csvReport() {
      const csvMetricData = this.products.map((product) => {
        return {
          title: product.title,
          stock_quantity: product.stock_quantity,
          sold_quantity: product.sold_quantity,
          minimum_stock: product.minimum_stock,
          replacement_quantity: product.replacement_quantity,
        };
      });
      const csvMetrics = new CSV(
        this.pdfHeader,
        csvMetricData,
        "Relatório Estoque Mínimo"
      );
      this.$refs.RefCSVReportLink.href = csvMetrics.execute();
      this.$refs.RefCSVReportLink.download = csvMetrics.name;
    },
  },
  watch: {
    selectedMachine(value) {
      if (!value) return;
      console.log(value);

      this.getStockProductMetrics();
    },
  },
};
</script>

<style lang="css" scoped>
</style>