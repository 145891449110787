<template>
  <div>
    <LoadingSpinner :show="loading" />
    <ModalUpdateProduct
      :productImage="productImage"
      :machineId="machineId"
      :formProductUpdate="formProductUpdate"
      @refresh-page="receiveEmitRefreshPage"
    />
    <ModalRemoveProduct
      :productImage="productImage"
      :machineId="machineId"
      :formRemoveProduct="formRemoveProduct"
      @refresh-page="receiveEmitRefreshPage"
    />
    <ModalAddProduct
      :productImage="productImage"
      :machineId="machineId"
      :formAddProduct="formAddProduct"
      :stockProducts="products"
      @refresh-page="receiveEmitRefreshPage"
    />
    <ModalAddProductOnMachine
      :productImage="productImage"
      :machineId="machineId"
      :machineProducts="machineProducts"
      :multiSelectStockProducts="multiSelectStockProducts"
      @refresh-page="receiveEmitRefreshPage"
    />

    <div class="col-12 my-5">
      <div class="row align-items-center">
        <div class="col-12 col-md-6">
          <base-input
            label="Pesquisar Produto"
            v-model="search"
            placeholder="Ex:. Coca cola"
          >
          </base-input>
        </div>
        <div class="col-12 col-md-6 text-right">
          <p class="personalized-badge mr-3">
            {{ totalFilteredProducts }} Produtos
          </p>
          <base-button class="mr-3" @click="isSort = !isSort" icon simple>
            <unicon
              :name="isSort ? 'sort-amount-up' : 'sort-amount-down'"
              fill="#1d8cf8"
            ></unicon>
          </base-button>
          <a
            class="mr-3"
            @click="handleGenerateReports({ type: 'csv' })"
            ref="RefCSVGeneralStock"
          >
            <base-button type="success">Excel</base-button></a
          >
          <base-button
            data-toggle="modal"
            data-target="#modalInsertProduct"
            type="success"
            simple
          >
            Adicionar Produto
          </base-button>
        </div>
      </div>
    </div>

    <table class="table table-responsive-xl text-center">
      <thead>
        <tr>
          <th scope="col">Imagem do Produto</th>
          <th scope="col">Produto</th>
          <th scope="col">Cod. de Barras</th>
          <th scope="col">Descrição</th>
          <th scope="col">Quant Máquina</th>
          <th scope="col">Preço de venda</th>
          <th scope="col">Adicionar</th>
          <th scope="col">Remover</th>
          <th>Editar</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="product of filteredMachineProducts"
          :key="product.expiration + product.product_id"
        >
          <td>
            <ProductImage :size="75" :src="product.image" />
          </td>
          <td>
            <p class="h4 text-white mx-2 font-weight-bold">
              {{ product.title }}
            </p>
          </td>
          <td>
            <p class="h4 text-white mx-2 font-weight-bold">
              {{ product.bar_code }}
            </p>
          </td>
          <td>
            <p class="h4 text-white mx-2 font-weight-bold">
              {{ product.description }}
            </p>
          </td>
          <td>
            <p class="text-white font-weight-bold">
              {{ product.amount }}
            </p>
          </td>
          <td>
            <p class="text-white font-weight-bold">
              {{ toBRL(product.unit_price / 100) }}
            </p>
          </td>
          <td>
            <base-button
              @click="handleModalAddProduct(product)"
              data-toggle="modal"
              data-target="#modalAddProduct"
              link
            >
              <unicon name="plus" fill="#00f2c3"></unicon>
            </base-button>
          </td>
          <td>
            <base-button
              @click="handleModalRemoveProduct(product)"
              data-toggle="modal"
              data-target="#modalRemoveProduct"
              link
            >
              <unicon name="minus" fill="#fd5d93" width="22px"></unicon>
            </base-button>
          </td>
          <td>
            <base-button
              @click="handleModalUpdateData(product)"
              data-toggle="modal"
              data-target="#modalUpdateProduct"
              link
            >
              <unicon name="edit" fill="#1d8cf8" width="22px"></unicon>
            </base-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import ModalAddProduct from "./Machine/Modal/ModalAddProduct.vue";
import ModalAddProductOnMachine from "./Machine/Modal/ModalAddProductOnMachine.vue";
import ModalRemoveProduct from "./Machine/Modal/ModalRemoveProduct.vue";
import ModalUpdateProduct from "./Machine/Modal/ModalUpdateProduct.vue";
import ProductImage from "../components/ProductImage.vue";
import LoadingSpinner from "./Spinner.vue";
import CSV from "../services/CSV";

moment.locale("pt-BR");

export default {
  name: "MachineStockProductsList",
  components: {
    ModalUpdateProduct,
    ModalRemoveProduct,
    ModalAddProduct,
    ModalAddProductOnMachine,
    ProductImage,
    LoadingSpinner,
  },
  props: {
    machineId: {
      type: String,
    },
  },
  data() {
    return {
      isSort: false,
      loading: false,
      productImage: "",
      machineProducts: [],
      productAmountAvailability: 0,
      products: [],
      search: "",
      warehouse_id: null,
      company_id: null,
      formAddProduct: {
        product_id: "",
      },
      formRemoveProduct: {
        product_id: "",
      },
      formProductUpdate: {
        product_id: "",
        title: "",
        description: "",
        price: 0,
        discount: 0,
      },
    };
  },
  async mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.warehouse_id = warehouse_id;
    this.company_id = company_id;
    await this.initialLoading();
  },
  methods: {
    async initialLoading() {
      await this.getProducts();
      await this.getMachineProducts();
    },
    handleModalAddProduct({ product_id, expiration, image }) {
      this.getStockProductQuantity(product_id, expiration);
      this.formAddProduct.product_id = product_id;
      this.productImage = image;
    },
    handleModalRemoveProduct({ product_id, image }) {
      this.formRemoveProduct.product_id = product_id;
      this.productImage = image;
    },
    handleModalUpdateData({
      product_id,
      title,
      description,
      unit_price,
      discount,
      image,
    }) {
      this.formProductUpdate.product_id = product_id;
      this.formProductUpdate.title = title;
      this.formProductUpdate.description = description;
      this.formProductUpdate.price = unit_price / 100;
      this.formProductUpdate.discount = discount / 100;
      this.productImage = image;
    },
    handleGenerateReports({ type }) {
      if (type == "csv") {
        this.csvReport();
      }
    },
    csvReport() {
      const data = this.filteredMachineProducts.map((product) => ({
        title: product.title,
        bar_code: product.bar_code,
        description: product.description,
        amount: product.amount,
        unit_price: this.toBRL(product.unit_price / 100),
        total: this.toBRL((product.amount * product.unit_price) / 100),
      }));
      const customCSV = new CSV(
        ["Nome", "Cod. de Barras", "Descricao", "Quant", "Preço", "V. Total"],
        data,
        "Relatório Estoque Máquina"
      );
      this.$refs.RefCSVGeneralStock.href = customCSV.execute();
      this.$refs.RefCSVGeneralStock.download = customCSV.name;
    },
    receiveEmitRefreshPage() {
      this.initialLoading();
    },
    toBRL(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    getStockProductQuantity(productId) {
      this.productAmountAvailability = 0;
      const productStockFilter = this.products.filter(
        (productStock) => productStock.product_id == productId
      );
      let totalQuantity = 0;
      productStockFilter[0].stock.forEach((stock) => {
        totalQuantity += stock.amount;
      });
      this.productAmountAvailability = totalQuantity;
    },
    async getProducts() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
        };
        const { data } = await this.$http.get("/products/stock", {
          params,
        });
        const { products } = await data;
        this.products = products;
      } catch (err) {
        this.$toast.error("Não foi possível buscar produtos");
      } finally {
        this.loading = false;
      }
    },
    async getMachineProducts() {
      try {
        this.loading = true;
        const params = {
          machine_id: this.machineId,
          company_id: this.company_id,
        };
        const { data } = await this.$http.get("/machine/products", {
          params,
        });
        const { machineProducts } = data;
        if (machineProducts) {
          this.machineProducts = machineProducts.map((product) => ({
            ...product,
            unit_price: product.unit_price,
          }));
        } else {
          this.machineProducts = [];
        }
      } catch (err) {
        this.$toast.error("Não foi possível buscar produtos da máquina");
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    multiSelectStockProducts() {
      let newProducts = [];
      this.products.forEach((product) => {
        for (const productStock in product.stock) {
          newProducts.push({
            ...product.stock[productStock],
            title: product.title,
            description: product.description,
            image: product.image,
            discount: product.discount,
          });
        }
      });
      return newProducts;
    },
    filteredMachineProducts() {
      const filter = this.machineProducts.filter(
        (product) =>
          product.title.toLowerCase().indexOf(this.search.toLowerCase()) != -1
      );
      return filter.sort((a, b) =>
        this.isSort ? a.amount > b.amount : a.amount < b.amount
      );
    },
    totalFilteredProducts() {
      return this.filteredMachineProducts.length;
    },
  },
};
</script>

<style scoped>
.personalized-badge {
  width: 100px;
  display: inline-block;
  text-align: center;
  background: #363959;
  font-size: 0.8rem;
  padding: 6px 0px;
  color: #b7bcdc;
  border-radius: 60px;
  margin: 0;
}
</style>
