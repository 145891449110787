<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
      :htmlToPdfOptions="{
        filename: `${name}_${dateFormatted(new Date(), true)}`,
        margin: [4, 0, 10, 0],
        html2canvas: {
          scale: 2,
        },
      }"
    >
      <div slot="pdf-content" style="padding: 20px">
        <div class="text-center">
          <img
            style="width: 128px"
            src="../../../public/img/favicon.png"
            alt="Snackly Logo"
          />
        </div>
        <h3 class="text-dark font-weight-bold text-center">
          {{ name.toUpperCase() }}
        </h3>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: row-reverse;
          "
        >
          <p class="text-dark" style="font-size: 12px">
            {{ user.name }} | {{ user.email }}
          </p>
          <p class="text-dark" style="font-size: 12px">
            Data do inventário {{ dateFormatted(date, true) }}
          </p>
        </div>
        <div style="margin-top: 1rem">
          <table table id="customers">
            <thead>
              <tr>
                <th>Vencimento</th>
                <th>Data Validade</th>
                <th>Quantidade</th>
                <th>Produto</th>
              </tr>
            </thead>
            <tbody class="text-dark">
              <tr
                class="avoid-break"
                v-for="(product, idx) in stockInventory"
                :key="idx"
              >
                <td>{{ product.daysToExpirationText }}</td>
                <td>
                  {{ dateFormatted(product.expiration) }}
                </td>
                <td>{{ product.quantity }}</td>
                <td>{{ product.title }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p
          class="text-dark mt-5 text-center font-weight-bold"
          style="font-size: 12px"
        >
          Luttos | {{ name }} {{ dateFormatted(date, true) }}
        </p>
      </div>
    </vue-html2pdf>
  </div>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import LoadingSpinner from "../../components/Spinner.vue";
moment.locale("pt-BR");

export default {
  name: "StockInventoryPDF",
  components: {
    VueHtml2pdf,
    LoadingSpinner,
  },
  mounted() {},
  props: {
    stockInventory: { type: Array },
    hasExpirationDate: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "Relatório",
    },
    user: {
      name: {
        type: String,
        email: String,
      },
    },
    date: { type: String },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    dateFormatted(date, hasHours = false) {
      return hasHours ? moment(date).format("L LT") : moment(date).format("L");
    },
    onProgress(event) {
      if (event == 100) {
        this.loading = false;
      }
    },
    hasGenerated() {
      this.loading = false;
    },
  },
};
</script>

<style lang="css" scoped>
.avoid-break {
  page-break-inside: avoid;
}
.items-total {
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

#customers td,
#customers th {
  padding: 4px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  background-color: #cb523d;
  color: white;
}
</style>
