<template>
  <card title="Relatório Estoque Geral">
    <LoadingSpinner :show="loading" />
    <p class="display-4">{{ selectedMachine.description }}</p>
    <div class="row align-items-center justify-content-between">
      <div class="col-12 col-md-6">
        <base-input
          label="Buscar Produto"
          v-model="search"
          placeholder="Ex:. Coca cola"
        >
        </base-input>
      </div>
      <div class="col-12 col-md-6">
        <label for="selectMachine" class="form-label text-light h4"
          >Escolha uma máquina</label
        >
        <select
          v-model="selectedMachine"
          class="form-control"
          id="selectMachine"
        >
          <option class="text-light" :selected="!selectedMachine" :value="{}">
            --- Selecione uma máquina ---
          </option>
          <option
            class="bg-light text-dark"
            :value="machine"
            :selected="selectedMachine"
            v-for="machine in machinesList"
            :key="machine.machine_id"
          >
            {{ machine.description }}
          </option>
        </select>
      </div>
    </div>
    <div class="row align-items-end justify-content-end">
      <div class="col-12 col-md-6 text-right mt-3">
        <a
          @click="handleGenerateReports({ type: 'csv' })"
          ref="RefCSVGeneralStock"
        >
          <base-button type="success">Excel</base-button></a
        >
      </div>
    </div>
    <table class="table table-responsive-xl">
      <thead>
        <tr>
          <th scope="col">Imagem</th>
          <th scope="col">Código de Barras</th>
          <th scope="col">Nome do Produto</th>
          <th scope="col">Quant. Depósito</th>
          <th scope="col">Quant. Máquina</th>
          <th scope="col">
            Quant. Total
            <base-button @click="isTotalSort = !isTotalSort" link>
              <unicon
                :name="isTotalSort ? 'sort-amount-up' : 'sort-amount-down'"
                fill="#fff"
                width="22px"
              ></unicon>
            </base-button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in filteredGeneralStock" :key="product.product_id">
          <td>
            <ProductImage :size="60" :src="product.image" />
          </td>
          <td class="text-white font-weight-bold">
            {{ product.bar_code }}
          </td>
          <td class="text-white font-weight-bold">{{ product.title }}</td>
          <td class="text-white font-weight-bold">
            {{ product.quant_dep }}
          </td>
          <td class="text-white font-weight-bold">
            {{ product.quant_machine }}
          </td>
          <td class="text-white font-weight-bold">
            {{ product.quant_total }}
          </td>
        </tr>
      </tbody>
    </table>
  </card>
</template>

<script>
import ProductImage from "../components/ProductImage.vue";
import LoadingSpinner from "../components/Spinner.vue";
import CSV from "../services/CSV";

export default {
  name: "GeneralStock",
  components: {
    ProductImage,
    LoadingSpinner,
  },
  data() {
    return {
      warehouse_id: "",
      company_id: "",
      loading: false,
      generalStock: [],
      selectedMachine: {},
      isTotalSort: false,
      search: "",
      machinesList: [],
    };
  },
  async mounted() {
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.warehouse_id = warehouse_id;
    this.company_id = company_id;
    await this.getMachines();
  },
  methods: {
    async getMachines() {
      try {
        this.loading = true;
        const { data } = await this.$http.get("/machines?limit=100", {
          params: {
            company_id:
              this.$store.getters["company/getManagerCompany"].company_id,
          },
        });
        console.log(data);

        this.machinesList = data.machines;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    async getGeneralStock() {
      try {
        if (!this.selectedMachine.machine_id) return;
        this.loading = true;
        const params = {
          company_id: this.company_id,
          warehouse_id: this.warehouse_id,
          machine_id: this.selectedMachine.machine_id,
        };
        const { data } = await this.$http.get("/stock/general", {
          params,
        });
        this.generalStock = data.products;
      } catch (err) {
        this.$toast.error("Não foi possível buscar produtos");
      } finally {
        this.loading = false;
      }
    },
    handleGenerateReports({ type }) {
      if (type == "csv") {
        this.csvReport();
      }
    },
    csvReport() {
      const data = this.generalStock.map((product) => {
        return {
          title: product.title,
          bar_code: product.bar_code,
          quant_dep: product.quant_dep,
          quant_machine: product.quant_machine,
          quant_total: product.quant_total,
        };
      });
      const customCSV = new CSV(
        [
          "Nome",
          "Código de Barras",
          "Quant. Depósito",
          "Quant. Machine",
          "Quant. Total",
        ],
        data,
        "Relatório Estoque Geral"
      );
      this.$refs.RefCSVGeneralStock.href = customCSV.execute();
      this.$refs.RefCSVGeneralStock.download = customCSV.name;
    },
  },
  watch: {
    selectedMachine(value) {
      if (!value) return;
      this.getGeneralStock();
    },
  },
  computed: {
    filteredGeneralStock() {
      let filter = this.generalStock.filter(
        (product) =>
          product.title.toLowerCase().indexOf(this.search.toLowerCase()) != -1
      );
      filter.sort((a, b) => {
        return this.isTotalSort
          ? a.quant_total > b.quant_total
          : a.quant_total < b.quant_total;
      });
      return filter;
    },
  },
};
</script>

<style scoped>
.card {
  padding: 20px !important;
}
</style>