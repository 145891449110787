<template>
  <card title="Relatório de Entradas/Saídas">
    <LoadingSpinner :show="loading" />
    <div class="row align-items-middle justify-content-between">
      <div class="col-12 col-md-6">
        <base-input
          v-model="searchProduct"
          placeholder="Buscar produto"
        ></base-input>
      </div>
      <div class="col-12 col-sm-6">
        <div class="select-dropdown-container" ref="targetDateDropdown">
          <div class="select-dropdown-title" @click="showFilter = !showFilter">
            <div class="icon-container">
              <div class="title-icon">
                <unicon fill="#fff" width="16px" name="calendar-alt"></unicon>
              </div>
              <p>{{ datesFilters[customDate] }}</p>
            </div>
            <div class="title-icon">
              <unicon
                fill="#fff"
                width="16px"
                :name="showFilter ? 'angle-up' : 'angle-down'"
              ></unicon>
            </div>
          </div>
          <div
            class="select-dropdown-content"
            :style="{ display: showFilter ? 'block' : 'none' }"
          >
            <div
              v-for="(value, key) in datesFilters"
              class="option-item"
              @click="() => handleDateFilter(key)"
              :key="key"
            >
              <p>{{ value }}</p>
            </div>
            <div class="option-item date" v-show="customDate == 9999">
              <div class="date-picker">
                <p class="date-picker-label">De:</p>
                <input type="date" v-model="startDate" />
              </div>
              <div class="date-picker">
                <p class="date-picker-label">Até:</p>
                <input type="date" v-model="endDate" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row align-items-center justify-content-end my-4">
      <div
        class="col-12 col-sm-6 d-flex align-items-center justify-content-end"
      >
        <base-button class="mr-3" @click="isSort = !isSort" icon simple>
          <unicon
            :name="isSort ? 'sort-amount-up' : 'sort-amount-down'"
            fill="#1d8cf8"
          ></unicon>
        </base-button>
        <a
          class="ml-3"
          ref="RefCSVReportLink"
          @click="generateCSVReport({ type: 'csv', diff: false })"
          ><base-button type="success">Excel</base-button></a
        >
      </div>
    </div>
    <div>
      <table
        class="table table-responsive-xl text-center"
        v-if="filteredStockMoviments.length > 0"
      >
        <thead>
          <tr>
            <th scope="col">Imagem</th>
            <th scope="col">Produto</th>
            <th scope="col">Cod. de Barras</th>
            <th scope="col">Usuário</th>
            <th scope="col">Tipo</th>
            <th scope="col">Ação</th>
            <th scope="col">Validade Lote</th>
            <th scope="col">Data Movimentação</th>
            <th scope="col">Quant.</th>
            <th scope="col">Preço. Custo</th>
            <th scope="col">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(productLog, idx) of filteredStockMoviments" :key="idx">
            <td>
              <div class="picture-container mx-auto">
                <ProductImage :size="45" :src="productLog.product.image" />
              </div>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ productLog.product.name }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ productLog.product.barcode }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ productLog.user.email }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ productLog.user.type }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ productLog.detail }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                <ExpirationBadge :date="productLog.expiration" />
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ dateFormatted(productLog.log_date) }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ productLog.product.quantity }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{ toBRL(productLog.product.price / 100) }}
              </p>
            </td>
            <td>
              <p class="text-white mx-2 font-weight-bold">
                {{
                  toBRL(
                    (productLog.product.quantity * productLog.product.price) /
                      100
                  )
                }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="text-center font-weight-bold p-4">Sem informações</p>
    </div>
    <h3 class="text-right">Valor total {{ toBRL(total / 100) }}</h3>
  </card>
</template>
<script>
import moment from "moment";
import LoadingSpinner from "../components/Spinner.vue";
import TabNavigation from "../components/TabNavigation.vue";
import CSV from "../services/CSV";
import BaseButton from "../components/BaseButton.vue";
import ProductImage from "../components/ProductImage.vue";
import BaseRadio from "../components/BaseRadio.vue";
import ExpirationBadge from "../components/ExpirationBadge.vue";

moment.locale("pt-BR");

export default {
  name: "stockReportMoviments",
  components: {
    LoadingSpinner,
    TabNavigation,
    BaseButton,
    BaseRadio,
    ProductImage,
    ExpirationBadge,
  },
  data() {
    return {
      showFilter: false,
      datesFilters: {
        1: "Hoje",
        2: "Ontem",
        7: "Últimos 7 Dias",
        15: "Últimos 15 Dias",
        30: "Últimos mês",
        9999: "Intervalo",
      },
      searchProduct: "",
      isSort: false,
      startDate: "",
      endDate: "",
      loading: false,
      stockMoviments: [],
      products: [],
      warehouse_id: "",
      company_id: "",
      customDate: null,
      csvHeaders: [
        "Produto",
        "Cod. de Barras",
        "Usuário",
        "Tipo",
        "Ação",
        "Validade Lote",
        "Data Movimentação",
        "Quant.",
        "Preço. Custo",
        "Total",
      ],
    };
  },
  async mounted() {
    document.addEventListener("click", this.handleClickOutside);
    const { company_id, warehouse_id } =
      this.$store.getters["company/getManagerCompany"];
    if (!company_id && !warehouse_id) {
      this.$toast.info(
        "Escolha uma Organização. Perfil > Selecionar organização"
      );
      return;
    }
    this.company_id = company_id;
    this.warehouse_id = warehouse_id;
    this.customDate = "1";
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      const targetDateDropdown = this.$refs.targetDateDropdown;
      if (!targetDateDropdown.contains(event.target)) {
        this.showFilter = false;
      }
    },
    async generateCSVReport() {
      const csvData = this.filteredStockMoviments.map((item) => ({
        product_name: item.product.name,
        barcode: item.product.barcode,
        user: item.user.email,
        user_type: item.user.type,
        action: item.detail,
        expiration: item.expiration,
        log_date: item.log_date,
        quantity: item.product.quantity,
        price: this.toBRL(item.product.price / 100),
        total: this.toBRL((item.product.price * item.product.quantity) / 100),
      }));
      const csvReport = new CSV(this.csvHeaders, csvData, "RELATÓRIO_ESTOQUE");
      this.$refs.RefCSVReportLink.href = csvReport.execute();
      this.$refs.RefCSVReportLink.download = csvReport.name;
    },
    resizeChartData(data, size = 10) {
      const newChartData = [];
      while (data.categories.length) {
        newChartData.push({
          categories: data.categories.splice(0, size),
          series: [
            { name: "Quantidade", data: data.series[0].data.splice(0, size) },
          ],
          unitPrices: { data: data.unitPrices.data.splice(0, size) },
        });
      }
      return newChartData;
    },
    dateFormatted(date) {
      return moment(date).format("L LT");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    toBRL(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    async handleDateChanges() {
      if (!this.startDate || !this.endDate) return;
      await this.getStockMoviments();
    },
    async getStockMoviments() {
      try {
        this.loading = true;
        const params = {
          company_id: this.company_id,
          start_date: this.startDate,
          end_date: this.endDate,
        };
        const response = await this.$http.get("stock/moviments", {
          params,
        });
        const { stockMoviments } = response.data;
        this.stockMoviments = stockMoviments;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err));
      }
    },
    handleDateFilter(data) {
      this.customDate = data;
      if (data == 9999) {
        this.startDate = "";
        this.endDate = "";
      } else {
        this.showFilter = false;
      }
    },
  },
  computed: {
    filteredStockMoviments() {
      const search = this.searchProduct.toLowerCase();
      const filter = this.stockMoviments.filter((item) =>
        item.product.name.toLowerCase().includes(search)
      );
      return filter.sort((itemA, itemB) => {
        const dateA = new Date(itemA.log_date);
        const dateB = new Date(itemB.log_date);
        return this.isSort ? dateA - dateB : dateB - dateA;
      });
    },

    total() {
      return this.filteredStockMoviments.reduce(
        (acc, curr) => (acc += curr.product.price * curr.product.quantity),
        0
      );
    },
  },
  watch: {
    customDate(newValue) {
      this.endDate = moment().format("YYYY-MM-DD");
      if (newValue == 1) {
        this.startDate = moment().format("YYYY-MM-DD");
      } else if (newValue == 2) {
        this.startDate = moment().day(-1).format("YYYY-MM-DD");
      } else if (newValue == 7) {
        this.startDate = moment().subtract(7, "day").format("YYYY-MM-DD");
      } else if (newValue == 15) {
        this.startDate = moment().subtract(15, "day").format("YYYY-MM-DD");
      } else if (newValue == 30) {
        this.startDate = moment().subtract(1, "month").format("YYYY-MM-DD");
      } else if (newValue == 12) {
        this.startDate = moment().subtract(1, "year").format("YYYY-MM-DD");
      }
    },
    startDate() {
      this.handleDateChanges();
    },
    endDate() {
      this.handleDateChanges();
    },
  },
};
</script>
<style lang="css" >
.card {
  padding: 20px !important;
}
.select-dropdown-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.select-dropdown-title {
  cursor: pointer;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #2b3553;
  border-radius: 5px;
}

.select-dropdown-title .icon-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

.icon-container > p {
  margin: 0;
}

.select-dropdown-content {
  position: absolute;
  top: 44px;
  display: flex;
  width: 100%;
  z-index: 10;
  border-radius: 4px;
  border: 1px solid #2b3553;
  overflow: hidden;
  background: #1c2134;
}

.select-dropdown-content > .option-item {
  padding: 8px 24px;
  cursor: pointer;
  transition: background 100ms;
  background: #1c2134;
}

.select-dropdown-content > .option-item:hover {
  background: #22283e;
}

.option-item > p {
  font-size: 12px;
  font-weight: 500;
}

.option-item.date {
  padding: 16px 24px;
}

.option-item.date:hover {
  background: transparent;
}

.date-picker > .date-picker-label {
  font-size: 12px;
}
.date-picker > input {
  padding: 8px;
  margin-bottom: 4px;
  width: 100%;
  font-size: 12px;
}
</style>
