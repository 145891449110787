const Machine = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Machine.vue");
const MachineCreateForm = () =>
  import(/* webpackChunkName: "common" */ "@/components/MachineCreateForm.vue");
const MachineEditForm = () =>
  import(/* webpackChunkName: "common" */ "@/components/MachineEditForm.vue");
const Machines = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Machines.vue");
const MachineReportMoviments = () =>
  import(/* webpackChunkName: "common" */ "@/pages/machineReportMoviments.vue");

const machineRoutes = [
  {
    path: "machines",
    name: "machines",
    component: Machines,
    meta: { requiresAuth: true },
  },
  {
    path: "machine/:id",
    name: "machine",
    component: Machine,
    meta: { requiresAuth: true },
  },
  {
    path: "machine/edit/:id",
    name: "machineEdit",
    component: MachineEditForm,
    meta: { requiresAuth: true },
  },
  {
    path: "machines/create",
    name: "createMachine",
    component: MachineCreateForm,
    meta: { requiresAuth: true },
  },
  {
    path: "machine/report/moviments",
    name: "machineReportMoviments",
    component: MachineReportMoviments,
    meta: { requiresAuth: true },
  },
];

export { machineRoutes };
